<template>
  <div class="image-uploader-container">
    <b-badge variant="secondary" v-if="checkSize" >كەڭلىك {{ width }} پېكسىل </b-badge>
    <b-badge variant="secondary" v-if="checkSize" class="mx-1">ئېگىزلىك {{ height }} پېكسىل </b-badge>
    <file-pond
      :id="id"
      :name="name"
      ref="pond"
      :allow-multiple="false"
      :accepted-file-types="acceptedFileTypes"
      :server="server"
      :files="files"
      :label-idle="btnLabel"
      labelInvalidField="رەسىم فورماتىدا خاتالىق بار"
      labelFileWaitingForSize="رەسىم سىغىمى دەللىلىنىۋاتىدۇ"
      labelFileSizeNotAvailable="رەسىم سىغىمىدا خاتالىق بار"
      labelFileLoading="يۈكلەش ..."
      labelFileLoadError="يۈكلەش مەغلۇپ بولدى"
      labelFileProcessing="يوللىنىۋاتىدۇ ..."
      labelFileProcessingComplete="يوللىنىپ بولۇندى"
      labelFileProcessingAborted="يوللاش بىكار قىلىندى"
      labelFileProcessingError="يوللاش مەغلۇپ بولدى"
      labelFileProcessingRevertError="ئۆچۈرۈش مەغلۇپ بولدى"
      labelFileRemoveError="ئۆچۈرۈش مەغلۇپ بولدى"
      labelTapToCancel="بېسىپ يوللاشنى بىكار قىلىڭ"
      labelTapToRetry="بېسىپ قايتا سىناڭ"
      labelTapToUndo="ئۆچۈرۈش"
      labelButtonRemoveItem="ئۆچۈرۈش"
      labelButtonAbortItemLoad="بىكار قىلىش"
      labelButtonRetryItemLoad="قايتا سىناش"
      labelButtonAbortItemProcessing="بىكار قىلىش"
      labelButtonRetryItemProcessing="قايتا سىناش"
      labelButtonProcessItem="يوللاش"
      styleButtonRemoveItemPosition="left"
      styleButtonProcessItemPosition="left"
      styleLoadIndicatorPosition="left"
      styleProgressIndicatorPosition="left"
      :allowImageValidateSize="checkSize"
      :imageValidateSizeMinWidth="width"
      :imageValidateSizeMaxWidth="width"
      :imageValidateSizeMinHeight="height"
      :imageValidateSizeMaxHeight="height"
      imageValidateSizeLabelImageSizeTooSmall="رەسىم بەك كىچىك"
      imageValidateSizeLabelImageSizeTooBig="رەسىم بەك چوڭ"
      imageValidateSizeLabelExpectedMinSize="كەڭلىكى {minWidth}، ئېگىزلىكى {minHeight} پېكسىل بولسۇن"
      imageValidateSizeLabelExpectedMaxSize="كەڭلىكى {maxWidth}، ئېگىزلىكى {maxHeight} پېكسىل بولسۇن"
      :onerror="handleError"
      v-on:init="handleFilePondInit"/>
  </div>
</template>

<script>
  import {getToken} from "../../../common/jwt.service";
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond';
  // Import FilePond plugins
  // Please note that you need to install these plugins separately
  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
  // Create component
  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageValidateSize);

  export default {
    name: "ImageUploader",
    components: { FilePond },
    props: {
      name: {
        type: String,
        default: 'file'
      },
      id: {
        type: String,
        default: 'image_uploader'
      },
      btnLabel: {
        type: String,
        default: 'رەسىم تاللاڭ'
      },
      uploadForm: {
        type: Object,
        default: ()=> { return {} }
      },
      imgUrl: {
        type: String,
        default: ''
      },
      acceptedFileTypes: {
        type: String,
        default: 'image/jpeg, image/png, image/jpg, image/svg+xml, image/gif'
      },
      checkSize: Boolean,
      width: {
        type: Number,
        default: 1
      },
      height: {
        type: Number,
        default: 1
      },
    },
    watch: {
      'imgUrl': function(newUrl, oldUrl){
        console.log(newUrl);
        console.log(oldUrl);
        if ( newUrl && (newUrl !== oldUrl || ! oldUrl) ){
          this.files = [
            {
              source: this.imgUrl,
              status: 5,
              options: {
                type: 'local',
              }
            }
          ]
        }
      }
    },
    computed: {
      params(){
        return Object.assign({
          name: this.name,
        }, this.uploadForm);
      },
      /*handlePreview(){
        return this.previewUrl ? this.previewUrl : this.preview;
      },*/
      server(){
        return {
          url: this.url,
          process: {
            url: '/image-upload',
            method: 'POST',
            withCredentials: false,
            headers: this.headers,
            timeout: 7000,
            onload: (response)=>{
              let data = JSON.parse(response);
              data.id = this.id;
              return this.$emit('success', data);
            },
            onerror: null,
            ondata: (formData) => {
              for ( let item in this.params ){
                formData.append(item, this.params[item]);
              }
              return formData;
            }
          },
          revert: {
            url: '/image-destroy',
            method: 'DELETE',
            withCredentials: false,
            headers: this.headers,
            ondata: (formData) => {
              for ( let item in this.params ){
                formData.append(item, this.params[item]);
              }
              return formData;
            }
          },
          load: {
            url: '/image-fetch?file=',
            withCredentials: false,
            headers: this.headers,
          },
          fetch: {
            url: '/image-fetch?file=',
            withCredentials: false,
            headers: this.headers,
          }
        };
      }
    },
    data(){
      return {
        url: `${process.env.VUE_APP_API_URL}`,
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        files: [],
      };
    },
    methods: {
      handleFilePondInit: function() {
        console.log('FilePond has initialized');
        // FilePond instance methods are available on `this.$refs.pond`
        this.$emit('init')
      },
      handleError(e){
        setTimeout(()=>{
          // this.$refs.pond.removeFiles();
        }, 1200);
        return this.$emit('error', e);
      },
      addFile(path = null){
        this.files = [
          {
            source: path,
            status: 5,
            options: {
              type: 'local',
            }
          }
        ]
      },
    }
  }
</script>

<style scoped>
  @import "../../../assets/css/filepond.css";
  @import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
