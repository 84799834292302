<template>
  <div class="article-poster-form-container">
    <b-overlay :show="formBusy" rounded="sm">
      <portlet title=" تەشۋىقات رەسىم تەڭشىكى">
        <template v-slot:body>
          <div class="row">
            <div class="col-md-12">
              <b-form ref="filmPoster" @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col sm="6" md="3">
                    <b-form-group :state="validateState('background')" aria-describedby="background-live-feedback" id="input-group-cover-image" label="تەشۋىقات رەسىم قېلىپى" label-for="background">
                      <image-uploader id="background" @success="onUploadSuccess" :img-url="oldBackground" :upload-form="{strategy: 'poster'}" />
                      <b-form-invalid-feedback id="background-live-feedback">
                        تەشۋىقات رەسىم قېلىپى تاللاڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col md="4">
                    <b-form-group id="input-name-position" :state="validateState('position', 'name')" aria-describedby="name-position-feedback" label=" ئەسەر ئىسمى ئورنى" label-for="name-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
                    >
                      <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'name')" aria-describedby="name-position-feedback" v-model="formData.name.position.raw" :formatter="formatNamePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="name-position" />
                      <b-form-invalid-feedback id="name-position-feedback">
                        ئەسەر ئىسمى ئورنىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-name-size" number type="number" :state="validateState('size', 'name')" aria-describedby="name-size-feedback" label="ئەسەر ئىسمى رازمېرى" label-for="name-size" description="ئەسەر ئىسمى چوڭ كىچىكلىكىگە پۈتۈن سان قىممىتى تولدۇرۇڭ" >
                      <b-form-input number  style="direction: ltr;" :state="validateState('size', 'name')" aria-describedby="name-size-feedback" v-model="formData.name.size" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="name-size" />
                      <b-form-invalid-feedback id="name-size-feedback">
                        ئەسەر ئىسمى رازمېرىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-name-color" :state="validateState('color', 'name')" aria-describedby="name-color-feedback" label="ئەسەر ئىسمى رەڭگى" label-for="name-color">
                      <b-input-group append="#">
                        <b-form-input :formatter="formatColor" :state="validateState('color', 'name')" aria-describedby="name-color-feedback" placeholder="رەڭ نۇمۇرى HEX قىممىتىنى تولدۇرۇڭ" style="direction: ltr" v-model="formData.name.color" />
                        <b-form-invalid-feedback id="name-color-feedback">
                          ئەسەر ئىسمى رەڭ نۇمۇرىنى كىرگۈزۈڭ
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col md="4">
                    <b-form-group id="input-cover_image-size" :state="validateState('size', 'cover_image')" aria-describedby="cover_image-size-feedback" label="ئالبوم رەسىم رازمېرى" label-for="cover_image-size" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا كەڭلىكنى، ئاستىدىكى قۇرغا ئېگىزلىكنى كىرگۈزۈڭ"
                    >
                      <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('size', 'cover_image')" aria-describedby="cover_image-size-feedback" v-model="formData.cover_image.size.raw" :formatter="formatCoverImageSize" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="cover_image-size" />
                      <b-form-invalid-feedback id="cover_image-size-feedback">
                        ئەسەر ئالبوم رەسىم رازمېرىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group id="input-avatar-cover_image" :state="validateState('position', 'cover_image')" aria-describedby="cover_image-position-feedback" label="ئالبوم رەسىم ئورنى" label-for="cover_image-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
                    >
                      <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'cover_image')" aria-describedby="cover_image-position-feedback" v-model="formData.cover_image.position.raw" :formatter="formatCoverImagePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="cover_image-position" />
                      <b-form-invalid-feedback id="cover_image-position-feedback">
                        ئەسەر رەسىم ئورنىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group id="input-cover_image-circle" label="ئالبوم رەسىم يۇمىلاقلىنامدۇ" label-for="cover_image-circle" >
                      <b-form-radio-group id="cover_image-circle" v-model="formData.cover_image.circle" :options="[{text: 'شۇنداق', value: 1}, {text: 'ياق', value: 0}, ]" buttons />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col md="4">
                    <b-form-group id="input-qrCode-size" :state="validateState('size', 'qrCode')" aria-describedby="qrCode-size-feedback" label="ئىككىلىك كود رازمېرى" label-for="qrCode-size" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا كەڭلىكنى، ئاستىدىكى قۇرغا ئېگىزلىكنى كىرگۈزۈڭ"
                    >
                      <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('size', 'qrCode')" aria-describedby="qrCode-size-feedback" v-model="formData.qrCode.size.raw" :formatter="formatQrCodeSize" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="qrCode-size" />
                      <b-form-invalid-feedback id="qrCode-size-feedback">
                        ئىككىلىك كود رازمېرىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group id="input-qrCode-position" :state="validateState('position', 'qrCode')" aria-describedby="qrCode-position-feedback" label="ئىككىلىك كود ئورنى" label-for="qrCode-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
                    >
                      <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'qrCode')" aria-describedby="qrCode-position-feedback" v-model="formData.qrCode.position.raw" :formatter="formatQrCodePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="qrCode-position" />
                      <b-form-invalid-feedback id="qrCode-position-feedback">
                        ئىككىلىك كود ئورنىنى كىرگۈزۈڭ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group id="input-qrCode-style" label="ئىككىلىك كود شەكلى" label-for="qrCode-style" >
                      <b-form-radio-group id="qrCode-style" v-model="formData.qrCode.style" :options="[{text: 'چاچما كود', value: 1}, {text: 'ئىككىلىك كود', value: 2}, ]" buttons />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-button id="submit" type="submit" variant="primary">
                  ساقلاش
                </b-button>
              </b-form>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import articlePosterMixin from "../../../mixins/articlePosterMixin";

  export default {
    name: "ArticlePosterForm",
    components: { Portlet, ImageUploader },
    mixins: [validationMixin, formBusyMixin, articlePosterMixin, sweetAlertMixin],
    validations: {
      formData: {
        background: {
          required
        },
        cover_image: {
          size:     { required },
          position: { required }
        },
        qrCode: {
          size:     { required },
          position: { required }
        },
        name: {
          size:     { required },
          position: { required },
          color:    { required }
        }
      }
    },
    computed: {
      oldBackground(){
        return this.formData.background && this.formData.background || '';
      },
    },
    created() {
      this.getArticlePoster()
        .then(response=>{
          console.log(response);
          if ( response.data.hasOwnProperty('background') ){
            this.formData = response.data;
          }
        })
    },
    data(){
      return {
        formBusy: false,
        formData: {
          background: null,
          cover_image: {
            circle: 0,
            size: {
              width: null,
              height: null,
              raw: ''
            },
            position: {
              x: 0,
              y: 0,
              raw: ''
            }
          },
          name: {
            size: 20,
            position: {
              x: 0,
              y: 0,
              raw: ''
            },
            color: '#ffffff'
          },
          qrCode: {
            style: 1,
            size: {
              width: null,
              height: null,
              raw: ''
            },
            position: {
              x: 0,
              y: 0,
              raw: ''
            }
          }
        },
        suckerCanvas: null,
        suckerArea: [],
        isSucking: false
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(e){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        this.setArticlePoster(this.formData)
          .then(()=>{this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');})
      },
      onUploadSuccess(response){
        this.formData.background = response.path;
      },
      formatCoverImageSize(e){
        let [width, height] = this.formatString(e);
        Object.assign(this.formData.cover_image.size, {width, height});
        return e;
      },
      formatCoverImagePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.cover_image.position, {x, y});
        return e;
      },
      formatQrCodeSize(e){
        let [width, height] = this.formatString(e);
        Object.assign(this.formData.qrCode.size, {width, height});
        return e;
      },
      formatQrCodePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.qrCode.position, {x, y});
        return e;
      },
      formatNamePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.name.position, {x, y});
        return e;
      },
      formatString(str){
        if ( ! str ) return [];
        return str.split(/[(\r\n)\r\n]+/);
      },
      formatColor(e){
        if ( e.startsWith('#') ){
          return e.toUpperCase();
        }else {
          return '#' + e.toUpperCase();
        }
      },
    }
  }
</script>
