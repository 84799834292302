<template>
  <div class="comment-list">
    <error :errors="articleError" />
    <article-poster-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import ArticlePosterForm from "../../../components/poster/ArticlePosterForm";
  import articleMixin from "../../../../mixins/articleMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from 'vuex';
  export default {
    name: "index",
    mixins: [ articleMixin, formBusyMixin ],
    components: { Error, ArticlePosterForm },
    computed: {
      ...mapGetters(["articleError"])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەسەر باشقۇرۇش", route: "/articles/list" },
        { title: " ئەسەر تەشۋىقات رەسىم تەڭشىكى" },
      ]);
    },
    data(){
      return {};
    },
    methods: {}
  }
</script>
<style>
</style>
